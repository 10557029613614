import { useLocation, useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";

const { Title } = Typography;

const ErrorPage: React.FC = () => {
    const {state} = useLocation();
    const navigate = useNavigate();

    const redirectToIndex = () => navigate('/');

    return <div>
        <Title level={3}>{state}</Title>
        <Button onClick={redirectToIndex}>Проверить</Button>
    </div>;
}

export default ErrorPage;