import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, List, Select, Space } from "antd";
import { useState } from "react";
import { BlackStyleCssProps } from "../../common/css-props/BlackStyleCssProp";
import { IGood, IGoodGroup } from "../../common/types/OrderPriceTypes";
import useStorePreOrder, { IStoreItem, PRE_ORDER_GOODS_KEY } from "../../common/hooks/useStoreGoods";
import { createOrderFromLacalStorage } from "../../common/functions/createOrder";
import { useNavigate } from "react-router-dom";

const options = [
    {value: '', label: ''},
    {value: '1', label: 'Б. Юности 21б'},
    {value: '2', label: 'Б. Юности 24'},
    {value: '3', label: 'Щорса 46а'},
    {value: '4', label: 'Щорса 46б'},
    {value: '5', label: 'Спортивная 1а'},
    {value: '6', label: 'Степная 6'},
    {value: '7', label: '60 лет 10'},
    {value: '8', label: 'Северный'},
    {value: '9', label: 'Новосадовая'},
    {value: '10', label: 'Разумное'},
]

const getDescription:(item: IGood)=>React.ReactNode= (item) =>
    <>
        {`${item.typeBeer}, срок годности ${item.expire} дней, креопсть ${item.volume}, плотность ${item.density}`}
        <br/>
        цена {item.priceKega} р. за кегу
    </>;

const calcAmount: (groups: IGoodGroup[]) => number = (groups) => 
    groups.reduce((sum, group) => sum + group.goods.reduce((sum, good) => sum + good.amount, 0), 0);

const footerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100vw',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    border: '1px solid #40a9ff',
    color: 'darkgray',
    backgroundColor: 'white',
    padding: '5px'
}

const OrderPrice: React.FC = () => {
    const [adressId, setAdressId] = useState<string>('');
    const [groups, minusHandler, plusHandler, clearStore] = useStorePreOrder();
    const navigate = useNavigate();
    const amountAll = calcAmount(groups);

    const createOrder = () => {
        if(adressId!==''){
            let id = parseInt(adressId);
            createOrderFromLacalStorage(id).then(_=>{
                clearStore();
                navigate('/orders');
            }).catch(console.error);
        }
    }
    return <div>
        <Space>
            Адрес:
        </Space>
        <Select options={options} value={adressId} onChange={val=> setAdressId(val)} defaultValue={''} style={{width: '100%'}}/>
        {groups.map(x=><div style={{textAlign: 'left'}}>
            <h4>{x.title}</h4>
            <List
                itemLayout="vertical"
                dataSource={x.goods}
                size="small"
                style={{marginBottom: '50px'}}
                rowKey={item=>item.id}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[
                            <Space><MinusCircleOutlined onClick={_=>minusHandler(item)}/></Space>,
                            <Space style={BlackStyleCssProps}>{item.quantity} л.</Space>,
                            <Space><PlusCircleOutlined onClick={_=>plusHandler(item)}/></Space>,
                            <Space style={BlackStyleCssProps}>= {item.amount} р.</Space>
                        ]}
                        >
                        <List.Item.Meta
                            
                            title={<div>{item.name} <span style={{textAlign: 'right'}}>{item.price} р. л.</span></div>}
                            description={getDescription(item)}
                        />
                    </List.Item>
                )}
            />
            <Flex style={footerStyle} justify="space-between" align="center">
                <h3 style={{margin: '0'}}>Заказа на: {amountAll} р.</h3>
                <Button onClick={createOrder}>Оформить</Button>
            </Flex>
        </div>)}
    </div>
}
export default OrderPrice;