import { useEffect, useState } from "react";
import { IGood, IGoodGroup } from "../types/OrderPriceTypes";
import { $api } from "../httpInstance";

export interface IStoreItem{
    goodId: number;
    quantity: number;
}

export const PRE_ORDER_GOODS_KEY = 'pre-order-goods';

const changeStore: (goodId: number, operation: string)=>number = (goodId, operation) => {
    const str = localStorage.getItem(PRE_ORDER_GOODS_KEY);
    let items:IStoreItem[] = [];
    if(str!==undefined && str!==null)
        items = JSON.parse(str!);
    if(items.find(x=>x.goodId===goodId)===undefined)
        items.push({goodId: goodId, quantity: operation==='+' ? 30 : 0});
    else
        items = items.map(x=>{
            if(x.goodId===goodId && operation==='+') return {...x, quantity: x.quantity + 30 };
            if(x.goodId===goodId && operation==='-') return {...x, quantity: x.quantity>=30 ? x.quantity - 30 : 0 };
            return {...x};
        });
    localStorage.setItem(PRE_ORDER_GOODS_KEY, JSON.stringify(items));
    return items.find(x=>x.goodId===goodId)!.quantity;
}

const getGroups: ()=>Promise<IGoodGroup[]> = async () => 
    (await $api.get<IGoodGroup[]>('/goods')).data;

const useStorePreOrder = () => {
    const [groups, setGroups] = useState<IGoodGroup[]>([]);
    useEffect(()=>{
        getGroups().then(data=>{
            let store = localStorage.getItem(PRE_ORDER_GOODS_KEY);
            if(store!==null){
                let storeItems: IStoreItem[] = JSON.parse(store);
    
                
    
                const items = data.map(x=>{
                    const goods = x.goods.map(g=>{
                        const finded = storeItems.find(x=>x.goodId===g.id);
                        if(finded!==undefined) return {...g, quantity: finded.quantity, amount: finded.quantity * g.price};
                        return {...g, quantity: 0, amount: 0};
                    });
                    return {...x, goods};
                });
                setGroups(items);
            }
            else
                setGroups(data.map(x=>({...x, goods: x.goods.map(x=>({...x, quantity: 0, amount: 0}))})));
        })
        .catch(console.error);
    }, []);

    const plusQuantity:(item: IGood)=>void = (item) => {
        const _quantity = changeStore(item.id, '+');
        setGroups(groups.map(g=>{
            const goods = g.goods.map(x=>{
                if(x.id===item.id) return {...x, quantity: _quantity, amount: _quantity * x.price};
                return {...x};
            })
            return {...g, goods};
        }));
    }
    const minusQuantity: (item: IGood)=>void = (item) => {
        const quantity = changeStore(item.id, '-');
        setGroups(prev=>prev.map(g=>{
            const goods = g.goods.map(x=>{
                if(x.id===item.id) return {...x, quantity, amount: quantity * x.price};
                return {...x};
            })
            return {...g, goods};
        }));
    }

    const clearStore = () => {
        localStorage.setItem(PRE_ORDER_GOODS_KEY, JSON.stringify([]));
        setGroups(prev=>prev.map(x=>{
            const goods = x.goods.map(g=>({...g, quantity: 0, amount: 0}));
            return {...x, goods};
        }))
    }
    
    return [groups, minusQuantity, plusQuantity, clearStore] as [
        IGoodGroup[], (item: IGood)=>void, (item: IGood)=>void, ()=>void
    ];
}

export default useStorePreOrder;