import { DollarCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Segmented, Space } from "antd";
import { NavigateFunction, Outlet, useLocation, useNavigate } from "react-router-dom";
import { $api } from "../common/httpInstance";
import { useLayoutEffect } from "react";
import axios from "axios";

const testAuth:  (navigate: NavigateFunction)=>Promise<boolean> = async (navigate) => {
  try{
    await $api.get<string>('/test-auth');
  }
  catch(error){
    if(axios.isAxiosError(error) && error.response?.status===401)
      navigate('/login');
    if(axios.isAxiosError(error) && error.response?.status===500){
      navigate('/error', {state: error.response.data.message});
    }
  }
  return true;
}

const getSelectTab: (pathName: string)=>string = (pathname) => {
  let selectTab = '/';
  if(pathname==='/') selectTab = '/';
  if(pathname==='/orders') selectTab = '/orders';
  if(pathname.indexOf('/orders/')>-1) selectTab='user';
  if(pathname==='/debts') selectTab = '/debts';
  return selectTab;
}

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  useLayoutEffect(()=>{
    if(pathname!=='/login')
      testAuth(navigate);
  }, []);

  const selectTab = getSelectTab(pathname);
  const changeHandler: (value: string)=>void = value => navigate(value);

  return <div>
    <Space>
      <Segmented
        onChange={changeHandler}
        defaultValue='/'
        value={selectTab}
        options={[
          {
            label: (
              <div style={{ padding: 4 }}>
                <Avatar icon={<DollarCircleOutlined />} />
                <div>Прайс</div>
              </div>
            ),
            value: '/',
          },
          {
            label: (
              <div style={{ padding: 4 }}>
                <Avatar style={{ backgroundColor: '#f56a00' }}>З</Avatar>
                <div>Заказы</div>
              </div>
            ),
            value: '/orders',
          },
          {
            label: (
              <div style={{ padding: 4 }}>
                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                <div>Долги</div>
              </div>
            ),
            value: '/debts',
          },
        ]}
      />
      </Space>
      <div>
        <Outlet />
      </div>
    </div>
}

export default MainLayout;