import React, { useState } from "react";
import { Button, Input, Typography } from 'antd';
import { $api } from "../../common/httpInstance";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [inn, setInn] = useState<string>('');

    const authHandler = async () =>{
        try{
            const response = await $api.post('/login', {inn});
            navigate('/');
        }
        catch(error){
            if(axios.isAxiosError(error) && error.response?.status===401)
                alert("ИНН не существует в базе данных");
        }
    };

    return <div>
        <Title level={4}>Введите инн организации</Title>
        <Input value={inn} onChange={x=>setInn(x.target.value)} placeholder="ИНН вашей орагнизации"/>
        <Button onClick={authHandler} style={{marginTop: '15px'}}>Зарегистрироваться</Button>
    </div>
}

export default Login;