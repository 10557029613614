import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Layout, List, Space } from "antd";
import Item from "antd/es/list/Item";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigation, useParams } from "react-router-dom";
import { BlackStyleCssProps } from "../../common/css-props/BlackStyleCssProp";
import { $api } from "../../common/httpInstance";

const { Header, Footer, Sider, Content } = Layout;

interface IOrderRequest{
    id: number;
    orderDate: string;
    adressId: number;
    adress: string;
    quantityAll: number;
    amountAll: number;
    items: IOrderItem[]
}

interface IOrder{
    id: number;
    orderDate: Date;
    adressId: number;
    adress: string;
    quantityAll: number;
    amountAll: number;
    items: IOrderItem[]
}

interface IOrderItem{
    id: number;
    goodName: string;
    price: number;
    quantity: number;
    amount: number;
}

const getOrder: (id: number) => Promise<IOrder> = async (id) => {
    const response = await $api.get<IOrderRequest>(`/orders/${id}`);
    const data = response.data;
    return {
        id:data.id,
        orderDate: new Date(data.orderDate),
        adressId: data.adressId,
        adress: data.adress,
        quantityAll: data.quantityAll,
        amountAll: data.amountAll,
        items: data.items
    }
}

const Order: React.FC = () => {
    const { id } = useParams();
    const [order, setOrder] = useState<IOrder | null>(null);

    useEffect(()=>{
        getOrder(parseInt(id!)).then(setOrder).catch(console.error);
    }, []);

    /*
    const plusHandler: (item: IOrderItem) => void = (item) => setOrder(prev=>) setItems(prev=>prev.map(x=>{
        if(x.id===item.id) return {...x, quantity: x.quantity + 30, amount: x.price * (x.quantity + 30)};
        return {...x};
    }))
    const minusHandler: (item: IOrderItem) => void = (item) => setItems(prev=>prev.map(x=>{
        if(x.id===item.id) return {...x, quantity: x.quantity>30 ? x.quantity - 30 : 0, amount: x.quantity>30 ? x.price * (x.quantity - 30) : 0};
        return {...x};
    }))
    */
    if(order===null) return <></>;
    return <div style={{textAlign: 'left', margin: '5px'}}>
        <div>Дата: {dayjs(order?.orderDate).format('DD.MM.YYYY')}</div>
        <div>Адрес: {order?.adress}</div>
        <div>Стоимость: {order?.amountAll}</div>
        <div>
            <List
                itemLayout="vertical"
                dataSource={order?.items}
                size="small"
                renderItem={(item, index) => (
                    <List.Item
                        actions={[
                            <Space style={BlackStyleCssProps}>{item.quantity} л.</Space>,
                            <Space style={BlackStyleCssProps}> = {item.amount}</Space>

                        ]}
                    >
                        <List.Item.Meta
                            title={item.goodName}
                        />
                    </List.Item>
                )}
                />
        </div>
        <div style={{textAlign: 'center', marginTop: '15px'}}>
            <Button>Сохранить</Button>
        </div>
    </div>
}

export default Order;