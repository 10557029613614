import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import OrderPrice from './pages/order-price/OrderPrice';
import MainLayout from './layout/MainLayout';
import Orders from './pages/orders/Orders';
import Debts from './pages/debts/Debts';
import Order from './pages/orders/Order';
import Login from './pages/login/Login';
import ErrorPage from './pages/error/Error';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/error',
    element: <ErrorPage />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <OrderPrice />
      },
      {
        path: 'orders',
        element: <Orders />
      },
      {
        path: 'orders/:id',
        element: <Order />
      },
      {
        path: 'debts',
        element: <Debts />
      }
    ]
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
