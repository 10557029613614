import dayjs from "dayjs";
import { $api } from "../httpInstance";
import { IOrder, IOrderItem, IOrderListItem } from "../types/OrderTypes";
import { IOrganization } from "./getOrganization";
import { IStoreItem, PRE_ORDER_GOODS_KEY } from "../hooks/useStoreGoods";

export interface IOrderCreateRequest{
    adressId: number;
    items: IOrderCreateRequestItem[];
}

export interface IOrderCreateRequestItem{
    goodId: number;
    quantity: number;
}

interface IOrderListResponseItem{
    id: number;
    adressId: number;
    orderDate: string;
    adress: string;
    quantityAll: number;
    amountAll: number;
    isDeleted: boolean;
    isPayment: boolean;
    isActPayment: boolean;
}

interface IOrderResponse{
    id: number;
    orderDate: string;
    adressId: number;
    adress: string;
    quantityAll: number;
    isDeleted: boolean;
    isPayment: boolean;
    isActPayment: boolean;
    items: IOrderResponseItem[];
}

interface IOrderResponseItem{
    id: number;
    goodId: number;
    goodName: string;
    quantity: number;
    amount: number;
}

function mapToArray<T, K>(arr: T[], transform: (val: T) => K): K[] {
    return arr.map(transform);
}

const createOrder: (order:IOrderCreateRequest)=>Promise<number> = async (order) => {
    const response = await $api.post<IOrderResponse>('/orders');
    return response.data.id;
}

const createOrderFromLacalStorage: (adressId: number)=>Promise<void> = async(adressId) => {
    const str = localStorage.getItem(PRE_ORDER_GOODS_KEY);
    let items: IStoreItem[];
    if(str!==undefined && str!==null){
        items = JSON.parse(str);
        const respsnse = await $api.post('/orders',{adressId, items});
    }
}

const getOrders: ()=>Promise<IOrderListItem[]> = async () => {
    const orgResponse = await $api.get<IOrganization>('/organization');
    const organizationId = orgResponse.data.id;
    const startStr = dayjs().add(-14, 'day').format('DD.MM.YYYY');
    const stopStr = dayjs().format('DD.MM.YYYY');
    const response = await $api.get<IOrderListResponseItem[]>(`/orders?organizationId=${organizationId}&start=${startStr}&stop=${stopStr}`);
    return mapToArray<IOrderListResponseItem, IOrderListItem>(response.data, (x)=>({
        id:x.id,
        adressId: x.adressId,
        orderDate: new Date(x.orderDate),
        adress: x.adress,
        quantityAll: x.quantityAll,
        amountAll: x.amountAll,
        isDeleted: x.isDeleted,
        isPayment: x.isPayment,
        isActPayment: x.isActPayment
    }));
}

export {createOrder, createOrderFromLacalStorage, getOrders};