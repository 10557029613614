import { Button, List, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOrders } from "../../common/functions/createOrder";
import { IOrderListItem } from "../../common/types/OrderTypes";


const Orders: React.FC = () => {
    const [orders, setOrders] = useState<IOrderListItem[]>([]);
    const navigate = useNavigate();

    useEffect(()=>{
        getOrders().then(setOrders).catch(console.error);
    },[]);

    return <div style={{textAlign: 'left'}}>
        <List
            itemLayout="vertical"
            dataSource={orders}
            size="small"
            renderItem={(item, index) => (
                <List.Item
                    actions={[
                        <Space><Button onClick={_=>navigate('/orders/' + item.id)} size="small">Открыть</Button></Space>,
                        <Space><Button onClick={_=>navigate('/orders/' + item.id)} size="small">Повторить</Button></Space>
                    ]}
                >
                    <List.Item.Meta
                        title={dayjs(item.orderDate).format('DD.MM.YYYY') + ":   " + item.amountAll + 'р.'}
                        description={item.adress}
                    />
                </List.Item>
            )}
            />
    </div>;
}

export default Orders;